import api from "@/api";

const version = {
  list(params) {
    return api.get(`/version`, {
      params: params,
    });
  },
  create(data) {
    return api.post(`/version`, data);
  },
  get(pk) {
    return api.get(`/version/${pk}`);
  },
  changeState(pk, data) {
    return api.patch(`/version/${pk}/state`, data);
  },
  destroy(pk) {
    return api.delete(`/version/${pk}`);
  },
  updateURL(pk, data) {
    return api.patch(`/version/${pk}/url`, data);
  },
};

export default version;
