<template>
  <div id="d-version-description">
    <el-dialog title="版本描述" :visible.sync="visible" width="600px">
      <el-input
        size="small"
        type="textarea"
        v-model="description"
        disabled
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "d-version-description",
  data() {
    return {
      visible: false,
      description: "",
    };
  },
  methods: {
    handleShow(data) {
      this.visible = true;
      this.description = data;
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
