<template>
  <div id="version" v-loading="loading">
    <div class="cm-content">
      <h-version @add="handleAdd"></h-version>
      <br />
      <el-table :data="data">
        <el-table-column label="编号" prop="pk" width="80px"> </el-table-column>
        <el-table-column label="系统类型">
          <template slot-scope="scope">
            {{ scope.row.platform | formatAppType }}
          </template>
        </el-table-column>
        <el-table-column label="版本号" prop="version"> </el-table-column>
        <el-table-column
          label="最低版本"
          prop="lowest_version"
        ></el-table-column>
        <el-table-column
          label="最高版本"
          prop="highest_version"
        ></el-table-column>
        <el-table-column label="更新类型">
          <template slot-scope="scope">
            {{ scope.row.update_type | formatUpdateType }}
          </template>
        </el-table-column>
        <el-table-column label="版本描述">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleViewDesrciption(scope.row.description)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="包状态">
          <template slot-scope="scope">
            {{ scope.row.url | formatURLState }}
          </template>
        </el-table-column>
        <el-table-column label="下架/上架">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="-1"
              @change="
                handleChangeState(scope.row.pk, scope.row.state, scope.row.url)
              "
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handlePackage(scope.row.pk, scope.row.platform)"
            >
              包管理
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDestroy(scope.row.pk)"
              :disabled="scope.row.is_super === 1"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-version ref="dialog" @change="handleDialogChange"></d-version>
      <d-version-package
        ref="dialogPackage"
        @change="handleDialogChange"
      ></d-version-package>
      <d-version-description ref="dialogDescription"></d-version-description>
    </div>
  </div>
</template>

<script>
import HVersion from "@/components/headers/HVersion";
import DVersion from "@/components/dialogs/DVersion";
import DVersionPackage from "@/components/dialogs/DVersionPackage";
import DVersionDescription from "@/components/dialogs/DVersionDescription";

import version from "@/api/version";

export default {
  name: "version",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HVersion,
    DVersion,
    DVersionPackage,
    DVersionDescription,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      this.loading = true;
      version.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleAdd() {
      this.$refs.dialog.handleAdd();
    },
    handlePackage(pk, type) {
      this.$refs.dialogPackage.handleShow(pk, type);
    },
    handleChangeState(pk, state, url) {
      if (url === "") {
        this.$message({
          type: "warning",
          message: "请更新包！",
        });
        this.handleList(this.params);
        return;
      }
      this.$confirm("是否修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            state: state,
          };
          version
            .changeState(pk, data)
            .then(() => {
              this.$message({
                type: "warning",
                message: "修改成功！",
              });
              this.handleList(this.params);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
              this.handleList(this.params);
            });
        })
        .catch(() => {});
    },
    handleDestroy(pk) {
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          version
            .destroy(pk)
            .then(() => {
              this.handleList();
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleViewDesrciption(data) {
      this.$refs.dialogDescription.handleShow(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "version",
          query: params,
        })
        .catch(() => {});
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.$router
        .push({
          name: "version",
          query: val,
        })
        .catch(() => {});
    },
    handleDialogChange(val) {
      if (val) {
        this.handleList(this.params);
      } else {
        this.handleList();
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "version") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#version {
  display: flex;
  height: 100%;
}
</style>
