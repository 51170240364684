<template>
  <div id="d-version-package">
    <el-dialog
      title="包管理"
      :visible.sync="visible"
      @closed="handleClosed"
      width="600px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item v-if="type === 1">
          <el-upload
            :limit="1"
            drag
            :headers="setHeaders()"
            :action="setURL()"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            size="small"
            v-model="form.url"
            placeholder="地址"
            :disabled="type === 1"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import version from "@/api/version";

export default {
  name: "d-version-package",
  data() {
    return {
      visible: false,
      form: {
        url: "",
      },
      pk: null,
      type: 1,
    };
  },
  methods: {
    handleShow(pk, type) {
      this.pk = pk;
      this.type = type;
      this.visible = true;
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          version
            .updateURL(this.pk, this.form)
            .then(() => {
              this.visible = false;
              this.$message({
                type: "success",
                message: "添加成功！",
              });
              this.$emit("change", false);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        }
      });
    },
    handleUploadSuccess(response) {
      this.form.url = response.results.url;
    },
    handleUploadError() {
      this.$message({
        type: "warning",
        message: "上传失败！",
      });
    },
    setURL() {
      return `${process.env.VUE_APP_API_ROOT}/upload?sort=package`;
    },
    setHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem("auth.token")}`,
        application: "mq-admin-pc",
      };
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        url: "",
      };
      this.$refs.form.resetFields();
    },
  },
};
</script>
