<template>
  <div id="d-version">
    <el-dialog
      title="新增版本"
      :visible.sync="visible"
      @closed="handleClosed"
      width="600px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="系统类型" prop="platform">
          <el-radio-group v-model="form.platform">
            <el-radio :label="1">Android</el-radio>
            <el-radio :label="2">iOS</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="版本号" prop="version">
          <el-input
            size="small"
            type="text"
            v-model="form.version"
            placeholder="版本号"
          ></el-input>
        </el-form-item>
        <el-form-item label="最低版本" prop="lowest_version">
          <el-input
            size="small"
            type="text"
            v-model="form.lowest_version"
            placeholder="最低版本"
          ></el-input>
        </el-form-item>
        <el-form-item label="最高版本" prop="highest_version">
          <el-input
            size="small"
            type="text"
            v-model="form.highest_version"
            placeholder="最高版本"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新类型" prop="update_type">
          <el-radio-group v-model="form.update_type">
            <el-radio :label="1">一般更新</el-radio>
            <el-radio :label="2">强制更新</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="版本描述" prop="description">
          <el-input
            size="small"
            type="textarea"
            v-model="form.description"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import version from "@/api/version";

export default {
  name: "d-version",
  data() {
    return {
      visible: false,
      form: {
        platform: 1,
        version: "",
        lowest_version: "",
        highest_version: "",
        update_type: 1,
        description: "",
      },
    };
  },
  methods: {
    handleAdd() {
      this.visible = true;
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.form;
          version
            .create(data)
            .then(() => {
              this.visible = false;
              this.$message({
                type: "success",
                message: "添加成功！",
              });
              this.$emit("change", false);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        platform: 1,
        version: "",
        lowest_version: "",
        highest_version: "",
        update_type: 1,
        description: "",
      };
      this.$refs.form.resetFields();
    },
  },
};
</script>
